import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => {
  const browser = typeof window !== "undefined" && window
  return (
    <>
      {browser && (
        <Layout>
          <SEO title="404: Not found" />
          <h1>404: Not Found</h1>
          <p>
            You just hit a page that doesn't exist. If you think this is a
            mistake, contact us at support@lokalcr.com
          </p>
        </Layout>
      )}
    </>
  )
}

export default NotFoundPage
